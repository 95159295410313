import React, { useContext, useState } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import { InterviewPageStoreCtx } from 'src/pages/interview/store';
import { ScreeningContentStoreCtx } from 'src/pages/interview/screeningStore';
import { formMobileExpirationStatus } from 'src/utils/helperFunctions';
import { StepperItem } from 'src/components/stepper_item';
import { color } from 'src/utils/mui';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from '../submit_button';

import { useStyles } from './styles';
import { TimerLabel } from '../../../../../timer_label';

export const MobileHeader = observer(({ handleSubmitDialog, handleCompleteLaterDialog }) => {
    const [opened, handleOpen] = useState(false);
    const {
        interview, questions, incompletedQuestionsIndexes,
        changeQuestion, timerValue, currentQuestion
    } = useContext(InterviewPageStoreCtx);
    const {
        currentQuestion: currentScreeningQuestion,
        countAnswered, currentQuestionIndex,
        errorOnStart
    } = useContext(ScreeningContentStoreCtx);

    const {
        menuIcon, paperDrawer,
        drawerHeader, completedLaterLabel,
        completedLaterButton, backIcon,
        backIconButton, menuWrapper,
        statusWrapper, ttInfoWrapper,
        statusWrapperThemeEmphasis,
        headerLeftWrapper, stepsWrapper,
        headerWrapper, counter
    } = useStyles({});
    const { i18n, t } = useTranslation();
    const isES = i18n.language === 'es';

    if (!interview?.questionCount || !(currentQuestion || currentScreeningQuestion) || errorOnStart) return null;

    const {
        expirationTimeStatus, expirationDate, timerEnabled,
        timerCount, hideCompleteLaterButton, countScreeningQuestions,
        screeningStatus, questionCount
    } = interview;
    
    const answeredCount = currentQuestion ? (questions.length - incompletedQuestionsIndexes.length) : countAnswered;
    const totalCount = currentScreeningQuestion ? countScreeningQuestions : questionCount;
    const expirationDateLabel = moment(expirationDate).format(isES ? '[el] D [de] MMMM [de] YYYY' : 'MMMM Do YYYY');

    const headerStatus = (
        <div className={statusWrapper}>
            <div className={counter}>
                <span className={statusWrapperThemeEmphasis}>{answeredCount}</span> / {totalCount}
            </div>&middot;
            {(timerEnabled && !screeningStatus)
                ? (
                    <TimerLabel
                        xs
                        value={timerValue}
                        totalValue={timerCount * 60}
                    />
                )
                : <span style={{ paddingLeft: 15 }}>{formMobileExpirationStatus(expirationTimeStatus, i18n.language)}</span> }
        </div>
    );

    return (
        <>
            <div className={headerWrapper}>
                <div className={headerLeftWrapper}>
                    <IconButton
                        className={menuIcon}
                        onClick={() => handleOpen(true)}
                        color="inherit"
                        aria-label="Open drawer"
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                    {headerStatus}
                </div>
                <SubmitButton
                    handleSubmitDialog={handleSubmitDialog}
                    isAllCompleted={!incompletedQuestionsIndexes.length}
                />
            </div>
            <SwipeableDrawer
                open={opened}
                onClose={() => handleOpen(false)}
                onOpen={() => handleOpen(true)}
                classes={{ paper: paperDrawer }}
            >
                <div
                    tabIndex={0}
                    role="button"
                    onKeyDown={() => handleOpen(false)}
                    onClick={() => handleOpen(false)}
                >
                    <div className={drawerHeader}>
                        <div>
                            <IconButton
                                className={backIconButton}
                                onClick={() => handleOpen(false)}
                                color="inherit"
                                aria-label="Open drawer"
                                size="large"
                            >
                                <ArrowBack className={backIcon} />
                            </IconButton>
                        </div>
                        { (!hideCompleteLaterButton && screeningStatus !== 'screeningFailed') && (
                            <Button
                                onClick={handleCompleteLaterDialog}
                                classes={{
                                    root: completedLaterButton,
                                    label: completedLaterLabel
                                }}
                            >
                                <span style={{ color: color('themeColor') }}>
                                    {t('Complete Later')}
                                </span>
                            </Button>
                        )}
                    </div>
                    <div className={menuWrapper}>
                        <div className={ttInfoWrapper}>
                            {headerStatus}
                            <div>
                                {t('Assessment expires on {{expirationDateLabel}}', { expirationDateLabel })}
                            </div>
                        </div>
                        <div className={stepsWrapper}>
                            <div>
                                {
                                    Boolean(currentScreeningQuestion) ? [...Array(countScreeningQuestions)]
                                        .map((item, index) => (
                                            <StepperItem
                                                index={index + 1}
                                                key={index}
                                                item={{
                                                    questionHasAnswer: Boolean(index < currentQuestionIndex || questions?.length),
                                                    questionDescription: ' ',
                                                    isScreening: true
                                                }}
                                                disabled={!item}
                                                active={Boolean(currentQuestionIndex - 1 === index && currentScreeningQuestion)}
                                                changeQuestion={changeQuestion}
                                                opened={opened}
                                            />
                                        ))
                                    : questions?.map((item, index) => (
                                        <StepperItem
                                            index={index + 1}
                                            key={item.id}
                                            opened={opened}
                                            item={item}
                                            changeQuestion={changeQuestion}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    );
});
